import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDashboardState } from '../contexts/dashboardStateContext';
import { HandleWebsocketMessage, SetJWT, SetWebsocket } from '../reducer/dashboardReducer';
import { DebugLevels, log_text } from '../log';
import { FormControl, TextField, Button, Alert } from '@mui/material';
import { VerticalSpacer } from '../widgets/spacer';

// [ ] move to configuration file!
const LOGIN_URL = 'https://dev1.status3.it/auth/v1/login';
// const LOGIN_URL = 'http://localhost:9090/login';

// [ ] upgrade to wss!
// const WS_URL = 'ws://localhost:8080/ws';
const WS_URL = 'wss://dev1.status3.it/ws';
// const WS_URL = 'ws://localhost:9091/ws';

function LoginPage() {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [loginButtonDisabled, setLoginButtonDisabled] = useState<boolean>(true);

    const [errorMessage, setErrorMessage] = useState<string>('');

    const navigate = useNavigate();

    const { dashboardState, dispatch } = useDashboardState();

    // enable button on valid input
    useEffect(() => {
        setLoginButtonDisabled(!username || !password);
    }, [username, password])

    useEffect(() => {
        if (errorMessage !== '') {
            setTimeout(() => {
                setErrorMessage('');
            }, 5000);
        }
    }, [errorMessage])

    function login(username: string, password: string) {
        const reqOpts: RequestInit = {
            method: 'POST',
            // credentials: 'include',
            body: JSON.stringify({
                username: username,
                password: password,
            })
        }

        fetch(LOGIN_URL, reqOpts)
            .then(res => {
                switch (res.status) {
                    case 200:
                        res.json().then(body => {
                            log_text(body);
                            dispatch(new SetJWT(body.accessToken));
                            let ws = new WebSocket(WS_URL);
                            ws.onmessage = (message) => {
                                dispatch(new HandleWebsocketMessage(message.data));
                            }
                            dispatch(new SetWebsocket(ws));
                            navigate('/dashboard/overview')
                        });
                        break;

                    case 401:
                        res.text().then(val => log_text(val, DebugLevels.ERROR));
                        setErrorMessage('Login fehlgeschlagen');
                        break;
                    default:
                        break;
                }
            })
            .catch((e) => {
                log_text(e, DebugLevels.ERROR)
            })
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        login(username, password);
    }

    useEffect(() => {
    }, [dashboardState.websocket]);

    return (
        // <div className="login-page min-vh-100 min-vw-100 d-flex justify-content-center align-items-center" style={{ flexShrink: 0 }}>
        <div className="login-page" style={{ flexShrink: 0 }}>
            <main className='login-page-content'>
                <form onSubmit={onSubmit}>
                    <FormControl>
                        <TextField
                            type='text'
                            variant='outlined'
                            value={username}
                            autoComplete='username'

                            label="Benutzername"
                            onChange={e => setUsername(e.target.value)}
                        // required
                        />

                        <VerticalSpacer height={16} />

                        <TextField
                            type='password'
                            variant='outlined'
                            value={password}
                            autoComplete='password'

                            label="Passwort"
                            onChange={e => setPassword(e.target.value)}
                        />

                        <VerticalSpacer height={16} />

                        <Button
                            variant='contained'
                            type='submit'
                            disabled={loginButtonDisabled}
                        >
                            Login
                        </Button>

                        <VerticalSpacer height={16} />
                        {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
                    </FormControl>
                </form>

                {/* <Form action='/dashboard'>
                    <FormGroup className='mb-3' controlId='formBasicUsername'>
                        <Form.Label>Benutzer</Form.Label>
                        <Form.Control
                            type='text'
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            placeholder='Benutzername eingeben'
                            autoComplete='username'
                        />
                    </FormGroup>
                    <FormGroup className='mb-3' controlId='formBasicPassword'>
                        <Form.Label>Passwort</Form.Label>
                        <Form.Control
                            type='password'
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            placeholder='Passwort eingeben'
                            autoComplete='current-password'
                        />
                    </FormGroup>
                    <Alert style={errorMessage === '' ? { visibility: 'hidden' } : { visibility: 'visible' }} variant='danger'>{errorMessage}</Alert>
                    <Button variant='primary' type='submit' onClick={onSubmit} disabled={loginButtonDisabled}>
                        Login
                    </Button>
                </Form> */}
            </main>
        </div>
    );
}

export default LoginPage;