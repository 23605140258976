import { Device, DeviceState, DeviceType } from "./device";
import { DeviceProperty, NumberDeviceProperty } from "../deviceProperty";

export class NBX extends Device {
    // HEADER 
    // [ ] WTF is UBXVersion doing in the nbx data???
    UBXVersion: string = '';
    Garmin: string = '';

    // Voltages
    voltageProperties: [
        DeviceProperty<number>,
        DeviceProperty<number>,
        DeviceProperty<number>,
        DeviceProperty<number>,
    ] = [
            new NumberDeviceProperty('VDD'),
            new NumberDeviceProperty('VIN0'),
            new NumberDeviceProperty('VIN1'),
            new NumberDeviceProperty('VIN2'),
        ]

    // Data
    DIN2: number = 0;
    DIN3: number = 0;

    // Particle Information

    // FUG
    GSSI_DMO: number = 0;
    GSSI_TMO: number = 0;
    RSSI: number = -99;
    OPTA: string = '';
    CellState: string = '';

    // Position
    latitude?: number;
    longitude?: number;

    constructor(
        name: string,
        id: string,
        params: {
            // base device
            deviceState?: DeviceState,
            lastUpdate?: number,

            // Header
            UBXVersion?: string,
            Garmin?: string,

            // Voltages
            VDD?: number,
            VIN0?: number,
            VIN1?: number,
            VIN2?: number,

            // Data
            DIN2?: number,
            DIN3?: number,

            // Particle Information


            // FuG
            GSSI_DMO?: number,
            GSSI_TMO?: number,
            RSSI?: number,
            OPTA?: string,
            CellState?: string,

            // Position
            latitude?: number,
            longitude?: number,

        }
    ) {
        super(name, DeviceType.nbx, id, { lastUpdate: params.lastUpdate },);


        // optionals

        // Header
        if (params.UBXVersion) this.UBXVersion = params.UBXVersion;
        if (params.Garmin) this.Garmin = params.Garmin;

        // Voltages
        this.voltageProperties[0].setValue(params.VDD);
        this.voltageProperties[1].setValue(params.VIN0);
        this.voltageProperties[2].setValue(params.VIN1);
        this.voltageProperties[3].setValue(params.VIN2);

        // Data
        if (params.DIN2) this.DIN2 = params.DIN2;
        if (params.DIN2) this.DIN2 = params.DIN2;

        // Particle

        // FuG
        if (params.GSSI_DMO) this.GSSI_DMO = params.GSSI_DMO;
        if (params.GSSI_TMO) this.GSSI_TMO = params.GSSI_TMO;
        if (params.RSSI) this.RSSI = params.RSSI;
        if (params.OPTA) this.OPTA = params.OPTA;
        if (params.CellState) this.CellState = params.CellState;

        if (params.latitude) this.latitude = params.latitude;
        if (params.longitude) this.longitude = params.longitude;
    }

    // update() { }

    getState(): DeviceState {
        let state = DeviceState.OK;

        for (let i = 0; i < this.voltageProperties.length; i++) {
            let newState = this.voltageProperties.at(i)!.getDeviceState();
            if (newState < state) state = newState;
        }
        // this.voltageProperties.forEach(prop => {
        //     log_text(`prop: ${prop.name} -> ${prop.getDeviceState()}`, DebugLevels.ERROR)
        //     let newState = prop.getDeviceState();
        //     if (newState < state) state = newState;
        // });
        // if (this.voltageProperties[1].value <= 12.0) return DeviceState.WARNING;
        return state;
    }
}