export enum DeviceType { nbx, ubx, cvm };

export enum DeviceState {
    /** At least one datapoint is in alarm region */
    ALARM = 0,
    /** At least one datapoint is in warning region, but none is in alarm region */
    WARNING = 1,
    /** No Datapoint is in warning or alarm region */
    OK = 2,
    /** The date, when the device was last seen, is above a threshold */
    DATA_EXPIRED = 3,
    /** default state */
    NO_STATE = -1,
};

export function DeviceStateFromNumber(state: number): DeviceState {
    switch (state) {
        case 0:
        default:
            return DeviceState.NO_STATE;
        case 1:
            return DeviceState.ALARM;
        case 2:
            return DeviceState.WARNING;
        case 3:
            return DeviceState.OK;
        case 4:
            return DeviceState.DATA_EXPIRED;
    }
}

export abstract class Device {
    type: DeviceType;
    name: string;
    id: string;
    // state: DeviceState = DeviceState.OK;
    lastUpdate: number | undefined;

    // updateState(state: DeviceState) {
    //     this.state = state;
    // }

    abstract getState(): DeviceState;

    getColorState(): string {
        switch (this.getState()) {
            case DeviceState.OK:
                return 'green';
            case DeviceState.WARNING:
                return 'orange';
            case DeviceState.ALARM:
                return 'red';
            default:
                return 'gray';
        }
    }

    constructor(name: string, type: DeviceType, id: string, params: { lastUpdate?: number }) {
        this.name = name;
        this.type = type;
        this.id = id;
        // if (params.state !== undefined) this.state = params.state;
        this.lastUpdate = params.lastUpdate;
    }


}