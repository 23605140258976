import { DebugLevels, log_text } from "../log";
import { DeviceStateFromNumber } from "../models/devices/device";
import { DeviceNotification } from "../models/deviceNotification";
import { NBX } from "../models/devices/nbx";
import { Notification, TelegramNotification } from "../models/notifications";
import { UBX } from "../models/devices/ubx";
import { roundVoltage } from "../helper/data";

export enum UserRole { invalid, monitor, user, admin };

function getUserRoleFromString(userrole: string): UserRole {
    switch (userrole) {
        case 'admin':
            return UserRole.admin;
        case 'monitor':
            return UserRole.monitor;
        case 'user':
            return UserRole.user;
        default:
            return UserRole.invalid;
    }
}

// [ ] move this to the correct location in the project
export function getUserRoleName(userrole: UserRole): string {
    switch (userrole) {
        case UserRole.admin:
            return 'Administrator';
        case UserRole.monitor:
            return 'Monitor';
        case UserRole.user:
            return 'Benutzer';
        default:
            return 'Unbekannt';
    }
}

export type DashboardState = {
    // connection
    websocket: WebSocket | null,
    jwtToken: string,
    websocketMessages: string[],

    // user and organisation
    username: string,
    userrole: UserRole,
    organisationName: string,
    userEMail: string,

    // device related
    notifications: Notification[],
    deviceNotification: DeviceNotification<any>[],

    /**
     * Map <DeviceID, Device>
     */
    devices: Map<string, UBX | NBX>,
}

export const InitialDashboardState: DashboardState = {
    websocket: null,
    jwtToken: '',
    websocketMessages: [
        'message 1',
        'message 2'
    ],

    username: '',
    userrole: UserRole.invalid,
    organisationName: '',
    userEMail: '',


    devices: new Map<string, NBX | UBX>(),
    notifications: [],
    deviceNotification: [],
}


enum DashboardStateActionType {
    ResetDashboardState,
    SetWebsocket,
    CloseWebsocket,
    SetJWT,
    SetUsername,
    AddWebsocketMessage,

    // Devices
    UpdateDeviceName,
    SendDeviceCommand,

    // Notifications
    UpdateNotification,
    TestNotification,
    QueryNotifications,

    // Settings
    UpdateSettings
}

export interface DashboardStateAction {
    // type: string
    type: DashboardStateActionType
}

export class ResetDashboardState implements DashboardStateAction {
    type: DashboardStateActionType = DashboardStateActionType.ResetDashboardState;
}


export class SetWebsocket implements DashboardStateAction {
    type: DashboardStateActionType = DashboardStateActionType.SetWebsocket;
    websocket: WebSocket;

    constructor(webscoket: WebSocket) {
        this.websocket = webscoket;
    }
}

export class CloseWebsocket implements DashboardStateAction {
    type: DashboardStateActionType = DashboardStateActionType.CloseWebsocket;
}

export class SetJWT implements DashboardStateAction {
    type: DashboardStateActionType = DashboardStateActionType.SetJWT;

    jwt: string;
    constructor(jwt: string) {
        this.jwt = jwt;
    }
}

export class SetUsernameAction implements DashboardStateAction {
    // type: string = 'SetUsername';
    type: DashboardStateActionType = DashboardStateActionType.SetUsername;
    username: string;

    constructor(username: string) {
        this.username = username;
    }
}

export class HandleWebsocketMessage implements DashboardStateAction {
    // type: string = 'AddWebsocketMessage';
    type: DashboardStateActionType = DashboardStateActionType.AddWebsocketMessage;
    message: string;
    constructor(message: string) {
        this.message = message;
    }
}

export class TestNotificationAction implements DashboardStateAction {
    type: DashboardStateActionType = DashboardStateActionType.TestNotification;
    notificationID: string;
    constructor(notificationID: string) {
        this.notificationID = notificationID;
    }
}

export class SendDeviceCommandAction implements DashboardStateAction {
    type: DashboardStateActionType = DashboardStateActionType.SendDeviceCommand;
    deviceID: string;
    commandString: string;
    constructor(deviceID: string, commandString: string) {
        this.deviceID = deviceID;
        this.commandString = commandString;
    }
}

export class UpdateDeviceNameAction implements DashboardStateAction {
    type: DashboardStateActionType = DashboardStateActionType.UpdateDeviceName;
    deviceID: string;
    newDeviceName: string;
    constructor(deviceID: string, newDeviceName: string) {
        this.deviceID = deviceID;
        this.newDeviceName = newDeviceName;
    }
}

export class UpdateNotificationAction implements DashboardStateAction {
    type: DashboardStateActionType = DashboardStateActionType.UpdateNotification;
    notification: Notification;
    constructor(notification: Notification) {
        this.notification = notification;
    }
}

export class QueryNotificationsAction implements DashboardStateAction {
    type: DashboardStateActionType = DashboardStateActionType.QueryNotifications;
    // constructor() { }
}

export class UpdateSettingsAction implements DashboardStateAction {
    type: DashboardStateActionType = DashboardStateActionType.UpdateSettings;

    newPassword?: string;
    newEmail?: string;

    constructor(props: {
        newEmail?: string,
        newPassword?: string,
    }
    ) {
        this.newPassword = props.newPassword;
        this.newEmail = props.newEmail;
    }
}

// class SetOrganisationName implements DashboardStateAction {
//     type: string = 'SetOrganisationName';
//     organisationName: string;

//     constructor(organisationName: string) {
//         this.organisationName = organisationName;
//     }
// }

export const reducer: (state: DashboardState, action: DashboardStateAction) => DashboardState = (state, action) => {
    switch (action.type) {
        case DashboardStateActionType.ResetDashboardState:
            return InitialDashboardState;

        case DashboardStateActionType.SetWebsocket:
            return {
                ...state,
                websocket: (action as SetWebsocket).websocket
            }

        case DashboardStateActionType.CloseWebsocket:
            state.websocket?.close();
            return {
                ...state,
                websocket: null
            }

        case DashboardStateActionType.SetUsername:
            return {
                ...state,
                username: (action as SetUsernameAction).username
            }

        case DashboardStateActionType.SetJWT:
            return {
                ...state,
                jwtToken: (action as SetJWT).jwt
            }

        case DashboardStateActionType.UpdateNotification:
            log_text(action, DebugLevels.ERROR);
            state.websocket?.send(JSON.stringify({
                'type': 'request',
                'topic': 'update_notification',
                'value': (action as UpdateNotificationAction).notification
            }))
            return {
                ...state
            }

        case DashboardStateActionType.SendDeviceCommand:
            log_text(action, DebugLevels.ERROR);
            state.websocket?.send(JSON.stringify({
                'type': 'request',
                'topic': 'send_command',
                'value': {
                    'device_id': (action as SendDeviceCommandAction).deviceID,
                    'command': (action as SendDeviceCommandAction).commandString,
                }
            }))
            return {
                ...state
            }

        case DashboardStateActionType.UpdateDeviceName:
            state.websocket?.send(JSON.stringify({
                'type': 'request',
                'topic': 'update_device_name',
                'value': {
                    'device_id': (action as UpdateDeviceNameAction).deviceID,
                    'new_device_name': (action as UpdateDeviceNameAction).newDeviceName,
                }
            }))
            return {
                ...state
            }

        case DashboardStateActionType.QueryNotifications:
            state.websocket?.send(JSON.stringify({
                'type': 'request',
                'topic': 'notifications',
                // [ ] change type to the correct one
                'value': (action as TestNotificationAction).notificationID,
            }));
            return {
                ...state
            }

        case DashboardStateActionType.TestNotification:
            state.websocket?.send(JSON.stringify({
                'type': 'request',
                'topic': 'test_notification',
                'value': (action as TestNotificationAction).notificationID,
            }));
            return {
                ...state
            }

        case DashboardStateActionType.UpdateSettings:
            // see, which settings have to be sent
            const settings = action as UpdateSettingsAction;

            // send messages
            if (settings.newEmail && settings.newEmail !== state.userEMail)
                state.websocket?.send(JSON.stringify({
                    'type': 'request',
                    'topic': 'update_email',
                    'value': settings.newEmail,
                }))
            if (settings.newPassword)
                state.websocket?.send(JSON.stringify({
                    'type': 'request',
                    'topic': 'update_password',
                    'value': settings.newPassword,
                }))
            return {
                ...state,
            }

        case DashboardStateActionType.AddWebsocketMessage:
            const message = (action as HandleWebsocketMessage).message;
            let jsonMessage: any;
            try {
                jsonMessage = JSON.parse(message);
            } catch (e) {
                return state;
            }

            let messageType: string = jsonMessage['type'];

            switch (messageType) {
                // [?] check if this is needed! The planned way is to query the services for information
                // case 'initialData':
                //     let requestDevices: string = JSON.stringify({
                //         'type': 'request',
                //         'topic': 'devices'
                //     })
                //     state.websocket?.send(requestDevices);
                //     return {
                //         ...state,
                //         organisationName: jsonMessage['organisation_name'],
                //         username: jsonMessage['username'],
                //         userrole: getUserRoleFromNumber(jsonMessage['userrole']),
                //     }

                case 'info':
                    return {
                        ...state,
                        websocketMessages: [...state.websocketMessages, jsonMessage['message']]
                    }

                case 'request':
                    switch (jsonMessage['topic']) {
                        case 'jwt':
                            let toSend: string = JSON.stringify({
                                'type': 'response',
                                'topic': jsonMessage['topic'],
                                'success': state.jwtToken !== '',
                                'value': state.jwtToken,
                            })

                            state.websocket?.send(toSend);
                            break;

                        default:
                            break;
                    }
                    return state;

                case 'response':
                    // abort on success === false
                    switch (jsonMessage['topic']) {
                        case 'devices':
                            if (jsonMessage['success'] === false) {
                                return {
                                    ...state
                                }
                            }
                            let jsonDevices: object[] = JSON.parse(jsonMessage['value']).devices;

                            // devices needs to be cleared, so devices don't spill over from old logins
                            state.devices.clear();

                            // [?] check if this works
                            jsonDevices.forEach((device: any) => {
                                updateDevice(state, device);
                            })

                            return {
                                ...state,
                                devices: state.devices
                            }

                        case 'deviceUpdate':
                            let jsonDevice: object = JSON.parse(jsonMessage['value']);
                            updateDevice(state, jsonDevice);
                            return {
                                ...state,
                            }

                        case 'notifications':
                            if (!jsonMessage['success']) {
                                break;
                            }
                            if (jsonMessage['value'] === null) {
                                break;
                            }
                            let jsonNotifications: object[] = JSON.parse(jsonMessage['value']).notifications;
                            state.notifications = [];
                            jsonNotifications.forEach((notification) => {
                                updateNotification(state, notification);
                            })

                            return {
                                ...state,
                                notifications: state.notifications,
                            }

                        case 'organisationName':
                            if (!jsonMessage['success']) {
                                break;
                            }
                            return {
                                ...state,
                                organisationName: jsonMessage['value'],
                            };

                        case 'username':
                            if (!jsonMessage['success']) {
                                break;
                            }
                            return {
                                ...state,
                                username: jsonMessage['value'],
                            }

                        case 'userrole':
                            if (!jsonMessage['success']) {
                                break;
                            }
                            return {
                                ...state,
                                userrole: getUserRoleFromString(jsonMessage['value'])
                            }
                        case 'useremail':
                            if (!jsonMessage['success']) {
                                break;
                            }
                            return {
                                ...state,
                                userEMail: jsonMessage['value']
                            }

                        default:
                            break;
                    }
                    return state;


                default:
                    return {
                        ...state,
                        websocketMessages: [...state.websocketMessages, (action as HandleWebsocketMessage).message]
                    }

            }
    }
}

function updateNotification(state: DashboardState, notification: any) {
    switch (notification['type']) {
        case 'telegram':
            log_text(notification);
            let telegramNotification: TelegramNotification = new TelegramNotification(
                {
                    // basic information
                    notificationID: notification['notification_id'],
                    username: notification['username'],
                    databaseID: notification['database_id'],

                    // telegram information
                    apiToken: notification['api_token'],
                    channelID: notification['channel_id'],
                }
            );
            state.notifications.push(telegramNotification);
            break;

        default:
            break;
    }
}

function updateDevice(state: DashboardState, device: any) {
    switch (device['device_type']) {
        case 'nbx':
            let nbx: NBX = new NBX(
                device['device_name'],
                device['device_id'],
                {
                    lastUpdate: Date.parse(device['last_update']),
                    VDD: roundVoltage(device['vdd']),
                    VIN0: roundVoltage(device['vin0']),
                    VIN1: roundVoltage(device['vin1']),
                    VIN2: roundVoltage(device['vin2']),

                    DIN2: device['din2'],
                    DIN3: device['din3'],

                    // FUG
                    RSSI: device['tetra_rssi'],
                    CellState: device['tetra_cell_state'],
                    latitude: device['pos_latitude'],
                    longitude: device['pos_longitude']
                },
            )
            // devices.push(nbx);
            state.devices.set(device['device_id'], nbx);
            break;
        case 'ubx':
            let ubx: UBX = new UBX(
                device['hostname'],
                device['device_id'],
                {
                    softwareVersion: device['software_version'],
                    deviceState: DeviceStateFromNumber(device['device_state']),
                    // check if the last update is more than 10 days (?) ago
                    lastUpdate: Date.parse(device['last_update']),

                    // Voltages
                    VDD: roundVoltage(device['vdd']),
                    VIN0: roundVoltage(device['vin0']),
                    VIN1: roundVoltage(device['vin1']),

                    // Digital IO
                    din1: device['din_1'] === 1 ? true : false,
                    din2: device['din_2'] === 1 ? true : false,
                    dout1: device['dout_1'] === 1 ? true : false,
                    dout2: device['dout_2'] === 1 ? true : false,

                    // FuG 
                    fug_state: device['fug_state'],
                    fug_mode: device['fug_mode'],
                    fug_vol: device['fug_vol'],
                    folder_name: device['folder_name'],
                    fug_manufacturer: device['fug_manufacturer'],

                    c_reg_state: device['tetra_creg_state'],
                    cell_id: device['tetra_cell_id'],
                    gssi_name: device['tetra_group'],
                    tetra_gssi: device['tetra_gssi'],
                    issi: device['tetra_issi'],
                    rssi: device['tetra_rssi'],

                    tetra_status: device['tetra_status'],
                    tetra_net: device['tetra_net'],
                    tetra_opta: device['tetra_opta'],

                    // GSM
                    gsm_carrier: device['gsm_carrier'],
                    gsm_iccid: device['gsm_iccid'],
                    gsm_signal_strength: device['gsm_signal_strength'],

                    // Licences
                    bluetoothLicence: device['licence_bluetooth'] === 1 ? true : false,
                    dataLicence: device['licence_data'] === 1 ? true : false,
                    navigationLicence: device['licence_navigation'] === 1 ? true : false,
                }

            );
            // devices.push(ubx);
            state.devices.set(device['device_id'], ubx);
            break;

        default:
            break;
    }
}