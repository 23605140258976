import { useState } from "react";
import { DeviceStateOverview } from "../../widgets/dashboard/device_state_overview";
import { Collapse, IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { NBX } from "../../models/devices/nbx";
import { UBX } from "../../models/devices/ubx";
import { DeviceState } from "../../models/devices/device";
import { log_text } from "../../log";

export function MultiDeviceStateOverview(props: { devices: Map<string, NBX | UBX> }): JSX.Element {
    const [open, setOpen] = useState<boolean>(true);
    // const { dashboardState } = useDashboardState();

    // [ ] data has to be fetched from the dashboardState
    let nbx: NBX[] = [];
    let ubx: UBX[] = [];
    // dashboardState.devices.forEach((device) => {
    props.devices.forEach((device) => {
        if (device instanceof NBX) nbx.push(device);
        if (device instanceof UBX) ubx.push(device);
    });

    log_text(nbx);


    return (
        <>
            <h2>&Uuml;bersicht <IconButton onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton> </h2>
            <Collapse in={open}>
                <div className="devices-state-overview">
                    <DeviceStateOverview title='NBX' data={[
                        nbx.filter((d) => d.getState() === DeviceState.OK).length,
                        nbx.filter((d) => d.getState() === DeviceState.WARNING).length,
                        nbx.filter((d) => d.getState() === DeviceState.ALARM).length,
                        nbx.filter((d) => d.getState() === DeviceState.NO_STATE || d.getState() === DeviceState.DATA_EXPIRED).length,
                    ]} />
                    <DeviceStateOverview title='UBX' data={[
                        ubx.filter((d) => d.getState() === DeviceState.OK).length,
                        ubx.filter((d) => d.getState() === DeviceState.WARNING).length,
                        ubx.filter((d) => d.getState() === DeviceState.ALARM).length,
                        ubx.filter((d) => d.getState() === DeviceState.NO_STATE || d.getState() === DeviceState.DATA_EXPIRED).length,
                    ]} />
                    {/* <DeviceStateOverview title='Sirenen' data={null} />
                    <DeviceStateOverview title='CVM-Module' data={[0, 0, 3, 14]} /> */}
                </div>
            </Collapse>
        </>
    )
}