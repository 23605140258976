// rewrite to accept data with an interface

import { Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useRef } from "react";


type DeviceStateOverviewProps = {
    title: string;
    data: [number, number, number, number] | null;
}

// [ ] card

export function DeviceStateOverview(props: DeviceStateOverviewProps): JSX.Element {
    const canvasWidth = 200;
    const canvasHeight = 200;
    const arcRadius = 80;

    // data handling
    const entriesCount = props.data ? props.data.reduce((p, c) => p + c, 0) : 0;

    // drawing handling
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const drawArc = (beginValue: number, count: number, color: string, ctx: CanvasRenderingContext2D): void => {
        const arcOffset = -Math.PI / 2;
        ctx.strokeStyle = color;
        ctx.beginPath()
        ctx.arc(
            canvasWidth / 2,
            canvasHeight / 2,
            arcRadius,
            beginValue / entriesCount * (2 * Math.PI) + arcOffset,
            (beginValue + count) / entriesCount * (2 * Math.PI) + arcOffset,
        )
        ctx.stroke();
        if (count === 0) return;

        let relValue = (beginValue + count / 2.) / entriesCount;

        ctx.fillStyle = color;
        ctx.font = '18px serif';
        ctx.fillText(
            count.toString(),
            100 + 55 * Math.sin(relValue * 2 * Math.PI) - 5,
            100 - 55 * Math.cos(relValue * 2 * Math.PI) + 5,
        );
    }

    useEffect(() => {

        const canvas = canvasRef.current;
        if (canvas === null) return;

        const context = canvas!.getContext('2d')
        if (context === null) return;

        context.clearRect(0, 0, canvasWidth, canvasHeight);

        if (entriesCount === 0 || !props.data) {
            context.font = '18px serif';
            context.fillText('Keine Daten vorhanden', 10, 100);
            return
        }

        context.lineWidth = 10;

        // context.fillStyle = 'red';
        const colors: string[] = ['green', 'orange', 'red', 'grey'];
        let startValue = 0;

        props.data.forEach((val, idx) => {
            drawArc(startValue, val, colors[idx], context);
            startValue += val;
        });


    })

    return (
        <Card elevation={4}>
            <CardHeader title={props.title} />
            <CardContent>
                <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight} />
            </CardContent>
        </Card>
    )
}