import { icon } from "leaflet";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

import iconURL from "leaflet/dist/images/marker-icon.png";
import { useState } from "react";
import { Collapse, IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { log_text } from "../../log";

export interface MultiDeviceMapProps {
    Devices: {
        deviceName: string,
        latitude?: number,
        longitude?: number,
    }[]
}

export function MultiDeviceMap(props: MultiDeviceMapProps) {
    const [open, setOpen] = useState<boolean>(true);

    // only necessary for multiple devices
    // if (props.Devices.length == 0) return (
    //     <h1>Kein Ger&auml;t gefunden</h1>
    // );

    if (props.Devices.length === 0) {
        return <h1>Keine Ger&auml;tedaten vorhanden</h1>
    }

    log_text(props);
    if (props.Devices.every(device => { return device.latitude === undefined && device.longitude === undefined; })) {
        return <h1>Keine Positionsdaten vorhanden</h1>
    }

    let positions: { position: [number, number], deviceName: string }[] =
        props.Devices
            .filter(d => (d.latitude && d.longitude))
            .map(device => {
                return {
                    position: [device.latitude!, device.longitude!],
                    deviceName: device.deviceName,
                }
            });

    return (
        <>
            <h2>
                Karte
                <IconButton onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
            </h2>
            <Collapse in={open}>
                <MapContainer style={{ width: '100%', height: '500px' }} center={positions[0].position} zoom={13} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {
                        positions.map((position, idx) =>
                            <Marker key={idx} position={position.position} icon={icon({
                                iconUrl: iconURL,
                                iconAnchor: [10, 41],
                                popupAnchor: [2, -41],
                            })}>
                                <Popup>
                                    {/* <a href={"/dashboard/device/" + position.deviceName}>{position.deviceName}</a> */}
                                    <Link to={`/dashboard/device/${position.deviceName}`}>{position.deviceName}</Link>

                                </Popup>
                            </Marker>
                        )
                    }
                </MapContainer>
            </Collapse >
        </>
    )
}