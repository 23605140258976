import { NBX } from "../../models/devices/nbx";
import { CollapsableTable } from "../../widgets/dashboard/collapsable_table";
import { SingleDeviceMap } from "../../widgets/dashboard/device_map";
import { VerticalSpacer } from "../../widgets/spacer";
import { useDashboardState } from "../../contexts/dashboardStateContext";
import { UpdateDeviceNameAction, UserRole } from "../../reducer/dashboardReducer";
import { isMoreThan10DaysOld } from "../../helper/date";
import { CollapsableDeviceTable } from "../../widgets/dashboard/collapsable_device_table";
import { IconButton } from "@mui/material";
import { Build } from "@mui/icons-material";
import { useState } from "react";
import { ModalTextDialog } from "../../widgets/modalTextDialog";

interface NBXWidgetProps {
    model: NBX;
}

export default function NBXPage(props: NBXWidgetProps) {
    const { dashboardState, dispatch } = useDashboardState();
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const lastUpdateString: string =
        isMoreThan10DaysOld(props.model.lastUpdate)
            ? 'nie'
            : new Date(props.model.lastUpdate!).toLocaleString()

    return (
        <div className="dashboard-body">

            <h1>
                {dashboardState.userrole === UserRole.admin &&
                    <IconButton onClick={() => { setModalIsOpen(true) }}><Build /></IconButton>
                }
                Name: {props.model.name}
            </h1>
            <h2>ID: {props.model.id}</h2>

            <CollapsableTable title="NBX" details={[
                ['Version', props.model.UBXVersion],
                ['Letztes Update', lastUpdateString],
            ]} />


            <VerticalSpacer height={16} />
            <CollapsableDeviceTable title="Spannungen" details={
                props.model.voltageProperties
            } />

            <VerticalSpacer height={16} />

            <CollapsableTable title="Funkgerät" details={[
                ['RSSI', props.model.RSSI],
                ['Reg-Status', '???'],
                ['GSSI DMO', props.model.GSSI_DMO],
                ['GSSI TMO', props.model.GSSI_TMO],
                ['OPTA', props.model.OPTA],
            ]} />

            <VerticalSpacer height={16} />

            <SingleDeviceMap
                Device={{
                    latitude: props.model.latitude,
                    longitude: props.model.longitude,
                    deviceName: props.model.name ?? props.model.id,
                }}
            />

            <VerticalSpacer height={16} />

            {/* // [ ] use conditional widget */}
            {/* {createSendCommand(dashboardState)} */}

            {
                modalIsOpen &&
                <ModalTextDialog
                    isOpen={modalIsOpen}
                    setIsOpen={setModalIsOpen}
                    initialText={props.model.name}
                    onSubmit={(v) => { dispatch(new UpdateDeviceNameAction(props.model.id, v)) }}
                />
            }
        </div>
    )
}