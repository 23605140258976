import { useNavigate } from "react-router-dom";

function Redirecter() {
    const navigate = useNavigate();

    setTimeout(() =>
        navigate('/dashboard/overview')
        , 3000)

    return (
        <>
            navigate('/dashboard/overview')
        </>
    )
}

export default Redirecter;