import { DeviceState } from "./devices/device";
import { Notification } from "./notifications";

// abstract class DeviceNotificationThreshold<T> {
//     abstract shouldNotify(oldValue: T, newValue: T): boolean;
// }

// export class DeviceNotificationThresholdNumber extends DeviceNotificationThreshold<number> {
//     shouldNotify(oldValue: number, newValue: number): boolean {
//         if (this.thresholdType == "upper") {
//             if (newValue >= this.thresholdValue && oldValue < this.thresholdValue) {
//                 return true
//             }
//         }
//         if (this.thresholdType == "lower") {
//             if (newValue <= this.thresholdValue && oldValue > this.thresholdValue) {
//                 return true
//             }
//         }

//         return false;
//     }

//     thresholdType: "upper" | "lower";
//     thresholdValue: number;

//     constructor(thresholdType: "upper" | "lower", thresholdValue: number) {
//         super();
//         this.thresholdType = thresholdType;
//         this.thresholdValue = thresholdValue;
//     }
// }

// export class DeviceNotification<T> {
//     deviceType: "nbx" | "ubx";
//     deviceID: string;
//     deviceProperty: string;
//     deviceNotificationThreshold: DeviceNotificationThreshold<T>;

//     oldValue: T;

//     shouldNotify(newValue: T) {
//         let ret: boolean = this.deviceNotificationThreshold.shouldNotify(this.oldValue, newValue);
//         this.oldValue = newValue;
//         return ret;
//     }

//     constructor(
//         deviceType: "nbx" | "ubx",
//         deviceID: string,
//         deviceProperty: string,
//         deviceNotificationThreshold: DeviceNotificationThreshold<T>,
//         initValue: T,
//     ) {
//         this.deviceType = deviceType;
//         this.deviceID = deviceID;
//         this.deviceProperty = deviceProperty;
//         this.deviceNotificationThreshold = deviceNotificationThreshold;
//         this.oldValue = initValue;
//     }
// }

/**
 * This widget is only used to display stuff, not run any notification!
 */
export abstract class DeviceNotification<T> {
    databaseID: string = '';
    deviceState = DeviceState.OK;
    abstract getDeviceState(value: T): DeviceState;

}

export class BooleanDeviceNotification extends DeviceNotification<boolean> {
    getDeviceState(value: boolean): DeviceState {
        if (value && this.highValueTargetState) this.deviceState = this.highValueTargetState;
        if (!value && this.lowValueTargetState) this.deviceState = this.lowValueTargetState;
        return this.deviceState;
    }

    risingEdgeNotification?: Notification;
    highValueTargetState?: DeviceState;
    fallingEdgeNotification?: Notification;
    lowValueTargetState?: DeviceState;
}

export class StringDeviceNotification extends DeviceNotification<string> {
    getDeviceState(value: string): DeviceState {
        return DeviceState.NO_STATE;
    }
}

export class NumberDeviceNotification extends DeviceNotification<number> {
    getDeviceState(value: number): DeviceState {
        if (value === 0) return DeviceState.OK;
        if (this.lowerAlertValue && this.lowerAlertNotification && (value <= this.lowerAlertValue)) return DeviceState.ALARM;
        if (this.upperAlertValue && this.upperAlertNotification && (value >= this.upperAlertValue)) return DeviceState.ALARM;
        if (this.lowerWarningValue && this.lowerWarningNotification && (value <= this.lowerWarningValue)) return DeviceState.WARNING;
        if (this.upperWarningValue && this.upperWarningNotification && (value >= this.upperWarningValue)) return DeviceState.WARNING;
        return DeviceState.OK;
    }

    upperWarningValue?: number;
    upperAlertValue?: number;
    lowerWarningValue?: number;
    lowerAlertValue?: number;

    upperWarningNotification?: Notification;
    upperAlertNotification?: Notification;
    lowerWarningNotification?: Notification;
    lowerAlertNotification?: Notification;


}