import { Button, Card, TextField } from "@mui/material";
import { useDashboardState } from "../../contexts/dashboardStateContext";
import { SendDeviceCommandAction } from "../../reducer/dashboardReducer";
import { useState } from "react";

export function DeviceSendCommand(props: {
    deviceID: string
}) {
    const { dispatch } = useDashboardState();
    const [command, setCommand] = useState<string>("");
    return (
        <Card elevation={0} sx={{ display: 'flex' }}>
            <TextField
                sx={{ flexGrow: '10' }}
                placeholder="Befehl eingeben"
                value={command}
                onChange={e => setCommand(e.target.value)}
            />
            <Button
                variant="text"
                sx={{ flexGrow: '1' }}
                // onClick={() => { alert('befehl senden!') }}
                onClick={() => {
                    dispatch(new SendDeviceCommandAction(props.deviceID, command));
                }}
            >senden!</Button>
        </Card>
    );
}