import { useEffect, useState } from "react";
import { useDashboardState } from "../../contexts/dashboardStateContext";
import { getUserRoleName } from "../../reducer/dashboardReducer";
import { DebugLevels, log_text } from "../../log";
import { useDebug } from "../../contexts/debugContext";

// interface DashboardHeaderProps {
//     name: string;
// }

// function DashboardHeader(props: DashboardHeaderProps) {
function DashboardHeader() {
    const LOGO_URL: string = 'https://dev1.status3.it/data/v0/logo';
    // const LOGO_URL: string = 'http://localhost:8081/v0/logo';

    const [imageURL, setImageURL] = useState<string>('');
    const { dashboardState } = useDashboardState();
    const { debug } = useDebug();

    // [ ] store logo or logo-url in state to not fetch it on every page change!
    // [x] get logo
    useEffect(() => {
        if (dashboardState.jwtToken === '') return;
        if (imageURL !== '') return;

        if (debug) {
            fetch(LOGO_URL, {
                headers: [
                    // ['orga', 'frg'],
                    ['authorization', `Bearer ${dashboardState.jwtToken}`]
                ],
            })
                .then((res) => {
                    switch (res.status) {
                        case 200:
                            res.blob().then(imgBlob => {
                                setImageURL(URL.createObjectURL(imgBlob));
                            })
                            break;
                        default:
                            let img = document.querySelector<HTMLImageElement>('#dashboard-header-image');
                            img!.src = '/logo_frg.png';
                            break;
                    }
                })
                .catch(reason => {
                    log_text(reason, DebugLevels.ERROR)
                });
        }
    }, [dashboardState.jwtToken]);

    return (
        <header className="dashboard-page-main-header">
            {/* <h1 className="col-6 text-white align-self-center">{props.name}</h1> */}
            {/* <h1 className="col-6 text-light align-self-center">{dashboardState.organisationName}</h1> */}
            <h1 className="text-light" style={{ flexGrow: 6 }}>{dashboardState.organisationName}</h1>
            {/* <h3 className="col-2 text-light align-self-center">{dashboardState.username} - {getUserRoleName(dashboardState.userrole)}</h3> */}
            <h3 className="text-light" style={{ flexGrow: 2 }}>{dashboardState.username} - {getUserRoleName(dashboardState.userrole)}</h3>
            <div className="col"></div>
            {/* <img id='dashboard-header-image' className="col-2 align-self-center" src="/logo_frg.png" alt="Bild" /> */}
            {/* <img className='dashboard-header-image' style={{ flexGrow: 2 }} src={imageURL} alt="Bild" /> */}
        </header>
    )
}

export default DashboardHeader;