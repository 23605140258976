import { useNavigate, Outlet } from 'react-router-dom';
import { useEffect, useReducer } from 'react';
import { InitialDashboardState, reducer } from './reducer/dashboardReducer';
import { DashboardStateContext } from './contexts/dashboardStateContext';

import "@fontsource/roboto";
import { DebugContext } from './contexts/debugContext';

function App() {
  // overall app state
  const [dashboardState, dispatch] = useReducer(reducer, InitialDashboardState);
  const DEBUG: boolean = false;

  // navigation and routing
  const navigate = useNavigate();

  // AUTHORIZATION
  // Change of the jwtAccessToken
  useEffect(() => {
    // only use this(!) to get back to login screen and nothing else
    if ('' === dashboardState.jwtToken) {
      navigate('/login')
    }

    // otherwise the navigation does not work :(

    // @aulf: i don't need navigate in the deps-array, as i don't want to check this on every navigation!
    // eslint - disable - next - line react - hooks / exhaustive - deps
  }, [dashboardState.jwtToken])

  return (
    < DashboardStateContext.Provider value={{ dashboardState, dispatch }}>
      <DebugContext.Provider value={{ debug: DEBUG, setDebug: () => { } }}>
        <div className="App">
          <Outlet />
        </div>
      </DebugContext.Provider>
    </DashboardStateContext.Provider >
  );
}

export default App;
