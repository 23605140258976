import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDashboardState } from "../contexts/dashboardStateContext";
import { CloseWebsocket, ResetDashboardState } from "../reducer/dashboardReducer";

// [ ] move to global config file
const LOGOUT_URL = 'https://dev1.status3.it/auth/v1/logout';

export default function Logout() {
    const { dispatch } = useDashboardState();
    const navigate = useNavigate();

    const requestOptions: RequestInit = {
        method: 'POST',
    }


    fetch(LOGOUT_URL, requestOptions)
        .catch(e => {
            console.error(e)
        })
        .finally(() => {
            dispatch(new CloseWebsocket());
            dispatch(new ResetDashboardState());
        })

    useEffect(() => navigate('/'))

    return (<></>)
}