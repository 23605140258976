import { KeyboardArrowDown, KeyboardArrowUp, Visibility, VisibilityOff } from "@mui/icons-material";
import { Collapse, IconButton, Table, TableBody, TableCell, TableRow, TextField } from "@mui/material";
import React, { Dispatch, useState } from "react";
import { log_text } from "../../log";

// [ ] Add settings for alignment
type CollapsableEditableTableProps = {
    title: string

    details: (
        {
            label: string,
            value: any,
            setValue?: Dispatch<React.SetStateAction<string>>,

            // inputType?: "password" | "email" | "",
            helperText?: string,
            placeholder?: string,

            hidable?: boolean,

        })[]
}

export function CollapsableEditableTable(props: CollapsableEditableTableProps) {
    const [open, setOpen] = useState<boolean>(true);

    return (
        <>
            <h2>
                {props.title}
                <IconButton onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
            </h2>
            <Collapse in={open}>
                <Table>
                    <TableBody>
                        {props.details.map((entry, idx) => {
                            const setValue = entry.setValue;
                            log_text(setValue);

                            return MyTableRow(
                                {
                                    idx: idx,
                                    label: entry.label,
                                    value: entry.value,
                                    setValue: entry.setValue,
                                    // inputType: entry.inputType ?? "",
                                    helperText: entry.helperText ?? "",
                                    placeholder: entry.placeholder ?? "",
                                    hidable: entry.hidable,
                                }
                            )
                        }
                        )}
                    </TableBody>
                </Table>
            </Collapse>
        </>
    );
}

function MyTableRow(props: {
    idx: number,
    label: string,

    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>> | undefined,

    helperText: string,
    placeholder: string,

    hidable?: boolean,
}): JSX.Element {
    const [hidden, setHidden] = useState<boolean>(props.hidable ?? false);

    return (
        <TableRow key={props.idx}>
            <TableCell width={'30%'} align="left" >
                {props.label}
            </TableCell>

            <TableCell
                width={'70%'}
                align="center"
            >
                {
                    (props.setValue === undefined)
                        ?
                        props.value
                        :
                        <TextField
                            // fullWidth={true}
                            value={props.value}
                            onChange={(e) => {
                                props.setValue!(e.target.value)
                            }}
                            autoComplete="off"

                            type={hidden ? "password" : "text"}

                            variant="standard"
                            sx={{ input: { textAlign: "center" } }}

                            placeholder={props.placeholder}
                            helperText={props.helperText}

                            InputProps={props.hidable ? {
                                endAdornment: (
                                    <IconButton onClick={() => setHidden(!hidden)}>
                                        {hidden ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                )
                            } : {}}
                        />
                }
            </TableCell>
        </TableRow >
    )
}